export const year = 2024;

// function getDate(year: number, month: number, week: number, day: number) {
//     var firstDay = 1;
//     if (week < 0) {
//         month++;
//         firstDay--;
//     }
//     var date = new Date(year, month, (week * 7) + firstDay);
//     if (day < date.getDay()) {
//         day += 7;
//     }
//     date.setDate(date.getDate() - date.getDay() + day);
//     return date;
// }

// export const startDate = getDate(year, 10, 3, 4) // November, 4th week, Thursday
export const startDate = new Date(year, 10, 23); // Temporarily for 2024
console.log("startDate", startDate)
export const endDate = new Date(`December 25, ${year}`);